<template>
    <div class="contract-mgmt-container" v-if="!contractLoading">
      <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
      <h2 class="page-header">
          {{ $t('_contracts') }}
      </h2>
      <div class="table-container">
        <Table :columns="columns"
        :data="this.contracts"
        :searchable="false"
        pageSize=10
        @clickTableEvent="itemClicked"></Table>
      </div>
      <div id="pageSelection" class="radio-toolbar">
          <PageTurner
              @changePageEvent="changePage"
              :pageSize="pageSize"
              :totalItems="this.totalCount"
              :currentPage="selectedPage"
              :pageTurnerSize="10"
            ></PageTurner>
          </div>
    </div>
</template>

<script>
import Breadcrumbs from '../../../shared/components/Breadcrumbs.vue'
import Table from '../../../shared/components/Table.vue'
import PageTurner from '../../../shared/components/PageTurner.vue'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'App',
  components: {
    Table,
    PageTurner,
    Breadcrumbs
  },
  data: () => ({
    columns: [
      {
        name: 'Contract Id',
        key: 'contractId',
        sortable: false,
        clickable: false
      },
      {
        name: 'Reporting Unit Id',
        key: 'reportingUnitId',
        sortable: true,
        clickable: true
      },
      {
        name: 'Start Date',
        key: 'operationStartDate',
        sortable: true,
        clickable: false
      }
    ],
    contractLoading: true,
    selectedPage: 1,
    pageSize: 10,
    breadcrumbs: [
      {
        label: 'Dashboard',
        link: '/dashboard/overview',
        class: ''
      },
      {
        label: '_contracts',
        link: '',
        class: 'active'
      }
    ]
  }),
  async mounted () {
    await this.getContractList(this.selectedPage)
    this.contractLoading = false
  },
  computed: {
    ...mapState('ContractManagement', ['totalCount', 'contracts'])
  },
  methods: {
    ...mapActions('ContractManagement', ['getContractList']),
    itemClicked (column, item) {
      if (column === 'reportingUnitId') {
        window.open('/dashboard/contract/' + item.reportingUnitId + '/details', '_blank')
      }
    },
    async changePage (number) {
      this.contractLoading = true
      this.selectedPage = number
      await this.getContractList(this.selectedPage)
      this.contractLoading = false
    }
  }
}
</script>

<style scoped>
#pageSelection {
  float: none;
  padding-top: 0.75rem;
}
.radio-toolbar div {
  display: flex;
  justify-content: center;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar span {
  float: left;
}

.page-header {
  padding: 5px;
  margin: 15px;
}

.radio-toolbar i {
  float: left;
  margin: 10px;
  align-self: center;
}

.radio-toolbar .page-label {
  font-size: 12px;
  padding: 5px;
}
</style>
